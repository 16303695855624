import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  CardActionArea,
  useTheme,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const ProductSection = () => {
  const classes = useStyles()
  const theme = useTheme()
  const products = [
    {
      title: 'Stor',
      link: null,
      image: (
        <StaticImage
          src="../../images/stor_logo.png"
          alt="Stor productivity unlocked"
          placeholder="blurred"
          layout="fixed"
          width={143}
          height={132}
          className={classes.image}
        />
      ),
      desc: 'Solusi manajemen inventaris, pembelian, dan faktur pada perusahaan Anda!',
    },
    {
      title: 'Raken',
      link: 'https://rakenid.com/',
      subTitle: 'smart living',
      image: (
        <StaticImage
          src="../../images/raken_logo.png"
          alt="Raken smart living"
          placeholder="blurred"
          layout="fixed"
          width={143}
          height={132}
          className={classes.image}
        />
      ),
      desc:
        'Nikmati berbelanja barang harian dengan mudah, Walau hujan badai Kami antar selamat sampai tujuan!',
      titleStyle: {
        color: theme.color.purple,
        textTransform: 'lowercase',
      },
      subTitleStyle: {
        color: theme.color.yellow,
        fontWeight: '600',
        textTransform: 'capitalize',
      },
    },
  ]

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        className={classes.titleSection}
        variant="h5"
      >
        Apa yang telah kami kerjakan?
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
        direction="row"
        spacing={3}
      >
        {products.map((item) => (
          <Grid
            item
            key={item.title}
          >
            <Card
              className={classes.card}
            >
              <CardActionArea
                target="_blank"
                rel="noreferrer"
                href={item.link}
              >
                <CardContent>
                  {item.image}
                  <div style={{
                    textAlign: 'center',
                    display: 'grid',
                  }}
                  >
                    <Typography
                      variant="h5"
                      className={classes.title}
                      style={item.titleStyle}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.subTitle}
                      style={item.subTitleStyle}
                    >
                      {item.subTitle}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.desc}
                    >
                      {item.desc}
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default ProductSection
