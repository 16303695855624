export default (theme) => {
  return {
    container: {
      padding: '4rem 0',
      textAlign: 'center',
    },
    text: {
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
    maintext: {
      fontWeight: '600',
      fontSize: '7rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '5rem',
      },
    },
    submaintext: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.2rem',
      },
    },
  }
}
