import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const HeroBanner = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography
        variant="h6"
        className={classes.text}
      >
        Welcome to
      </Typography>
      <Typography
        variant="h1"
        className={classes.maintext}
      >
        MAJIN
      </Typography>
      <Typography
        variant="h5"
        className={classes.submaintext}
      >
        Technology Design Studio
      </Typography>
    </div>
  )
}

export default HeroBanner
