import React from 'react'
import { graphql } from 'gatsby'

import Page from '~/src/components/page'
import Seo from '~/src/components/seo'
import Product from '~/src/components/product'
import ContactUs from '~/src/components/contact'
import Pricing from '~/src/components/pricing'
import HeroBanner from '~/src/components/hero-banner'
// import LatestNews from '~/src/components/latest-news'
import Background from '~/src/components/background/home'

const HomePage = ({ data }) => {
  return (
    <Page>
      <Seo
        site={data.site}
        title="Technology design studio in Padang, Indonesia"
        description="Spesialisasi dalam formulasi bisnis sistem, meningkatkan kehidupan melalui teknologi & desain, dan solusi digital web portal dan mobile application, kantor pusat di Padang, Indonesia"
        canonical="https://majin.land"
        ogImage="https://majin.land/images/majin_facebook.png"
      />
      <Background />
      <HeroBanner />
      {/* <LatestNews /> */}
      <Product />
      <Pricing />
      <ContactUs />
    </Page>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`

export default HomePage
