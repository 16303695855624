import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

const HomeBackground = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.bgTopLeft} />
      <div className={classes.bgTopRight} />
      <div className={classes.bgBottomLeft} />
    </>
  )
}

export default HomeBackground
