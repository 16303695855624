export default (theme) => {
  return {
    bgTopLeft: {
      position: 'absolute',
      top: '-5rem',
      width: '100%',
      height: '600px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0% 0%',
      background: 'url(/images/bg_top_left.svg)',
      zIndex: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    bgTopRight: {
      position: 'absolute',
      top: '-5rem',
      width: '100%',
      height: '800px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100% 0%',
      background: 'url(/images/bg_top_right.svg)',
      zIndex: -2,
    },
    bgBottomLeft: {
      position: 'absolute',
      width: '100%',
      height: '600px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '85% 0%',
      background: 'url(/images/bg_triangle.svg)',
      zIndex: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }
}
