export default (theme) => {
  return {
    container: {
      marginTop: '4rem',
    },
    titleSection: {
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    grid: {
      marginTop: '3.75rem',
      marginBottom: '4.5rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '2.25rem',
      },
    },
    card: {
      maxWidth: '18.5rem',
      borderRadius: '1.25rem',
    },
    image: {
      margin: '0 auto',
    },
    title: {
      fontWeight: '600',
      marginTop: '5px',
      textTransform: 'uppercase',
    },
    desc: {
      marginTop: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
    },
  }
}
